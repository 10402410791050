.map-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.sidebarStyle {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
  background-color: #404040;
  color: #ffffff;
  z-index: 1 !important;
  padding: 6px;
  font-weight: bold;
}

.map-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding: 0 -20px;
  border-radius: 10px;
  //box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  .mapboxgl-map {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    // canvas,
    // :global(.mapboxgl-canvas) {
    //   height: 100% !important;
    // }
  }
}
