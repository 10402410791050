.badge-background {
  padding: 1px 8px;
  border-radius: 2px;
  margin-bottom: 5px;
  height: 17px;
}

.badge-text {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 600;
}