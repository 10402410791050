.list-container-item{
  width: 100%;
  flex: 1 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  padding: 0.875rem 1rem;
  border-bottom: 1px solid rgba(14,14,44,0.1);
}

.list-container-item > .icon-container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 44px;
}

.list-container-item > .icon-container > .icon-wrapper{
  position: relative;
  background-color: rgba(173,180,188,.164);
  border-radius: 0.5rem;
  width: 44px;
  height: 44px;
  display:flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}

.list-container-item > .text-container{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: start;
  flex: 0 0 44px;
  margin-left:.5rem;
  flex: 1;
}

.list-container-item > .text-container > .title{
  font-weight: 500;
  font-size: .75rem;
  letter-spacing: .025em;
  color:rgba(0,0,0,.90);
  margin-bottom:0;
  font-family: 'Work Sans', sans-serif;
}

.list-container-item > .text-container > .description{
  font-weight: 600;
  font-size: .75rem;
  color:rgba(0,0,0,.55);
  font-weight: 500;
  margin-bottom:0;
  font-family: 'Work Sans', sans-serif;
}

.list-container-item > .text-container > .badge{
  background-color: rgba(173,180,188,.164);
  border-radius: .25rem;
  text-align: center;
  font-family: 'Work Sans', sans-serif;
  padding-left: .375rem;
  color: #77ad1a;
  font-weight: 500;
  padding-right: .375rem;
  font-size: .75rem;
  user-select: none;
}

.list-container-item > .text-container > .button-primary-white{
  padding: .375rem .75rem;
  color:#079992;
  font-size:13px;
  height: 30px;
  border-radius: .25rem;
  border: 1px solid #efeffd;
  font-weight: 500;
  line-height:1rem;
  font-family: 'Work Sans', sans-serif;
  background: rgb(255 255 255/var(--tw-bg-opacity));
  user-select: none;
}

.list-container-item > .text-container > .button-primary{
  padding: .375rem .75rem;
  color:rgba(255, 255, 255, 0.9);
  font-size:13px;
  height: 30px;
  border-radius: .25rem;
  border: 1px solid #efeffd;
  font-weight: 500;
  line-height:1rem;
  font-family: 'Work Sans', sans-serif;
  background-color: #079992;
  user-select: none;
}

