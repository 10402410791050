.fleet-item-tour-management {
  position: relative;
  display: block;
  background: white;
  border-radius: 13px;
  margin: 10px 0;
  box-shadow: 0px 1px 1px 0px rgba(14, 42, 90, 0.15);
  overflow: hidden;
  min-width: 385px;
  transition: all 0.2s;

  &.disabled {
    opacity: 0.5;
    transform: scale(0.98);
  }

  &:hover {
    // background: #fcfcfc;
    box-shadow: 0px 1px 1px 0px rgba(14, 42, 90, 0.23);

    .fr {
      >.fleet-item-title {
        color: #585757 !important;
      }

      >.fleet-item-desc {
        color: #646363;
      }
    }
  }

  .fleet-item-step-optimization-container-tr {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 17px;
      bottom: 17px;
      left: 119px;
      width: 3px;
      border-left: 2px dashed #e9ecef;
      z-index: 0;

      @media screen and (max-width: 1620px) {
        left: 59px;
      }
    }
  }

  .fleet-first-line-tour-management {
    width: 100%;
    display: flex;
    padding: 10px 15px;
    cursor: pointer;
    background-color: white;

    &:hover {
      .fleet-btn-suppr-tour {
        display: inline-block !important;
      }
    }

    .fleet-btn-suppr-tour {
      color: #e55039;

      &.toggleable {
        display: none;
      }

      .fleet-icon-trash-tour {
        height: 12px;
      }

      &:active {
        border-bottom: 0 solid transparent;
      }
    }
  }

  >.fleet-first-line-tour-management>.hw {
    flex: 0 0 105px;

    @media screen and (max-width: 1620px) {
      flex: 0 0 70px;
    }

    @media screen and (max-width: 1200px) {
      flex: 0 0 50px;
    }

    >.img-illustration-truck {
      position: absolute;
      top: -22px;
      height: 100px;
      width: auto;
      left: 0px;

      @media screen and (max-width: 1620px) {
        position: absolute;
        top: -15px;
        height: 90px;
        width: auto;
        left: 0px;
      }

      @media screen and (max-width: 1200px) {
        top: -13px;
        height: 80px;
        width: auto;
        left: 0px;
      }
    }
  }

  .fr {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    @media screen and (max-width: 1620px) {
      margin-left: 30px;
    }

    >.fleet-item-title-id {
      color: #079992;
      background-color: #c4e7e5;
      font-weight: 600;
      padding: 1px 8px;
      font-size: 10px;
      border-radius: 2px;
      text-transform: uppercase;
      margin-bottom: 5px;
    }

    >.fleet-item-title {
      white-space: nowrap;
      text-align: left;
      display: block;
      font-size: 16px;
      line-height: 1;
      font-weight: 700;
      color: #333333;
      margin-bottom: 0;

      @media screen and (max-width: 1200px) {
        font-size: 13px;
      }
    }

    >.fleet-item-desc {
      display: block;
      text-align: left;
      font-size: 11px;
      line-height: 1;
      font-weight: 500;
      text-transform: uppercase;
      color: #333333;

      @media screen and (max-width: 1200px) {
        font-size: 10px;
      }
    }

    flex-grow: 1;
    /* default 0 */
  }

  .hwe-auto {
    flex: 0 0 70px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    >span {
      color: #c7c7c7;

      &:hover {
        color: #929292;
      }
    }

    @media screen and (max-width: 991px) {
      padding-right: 0px;
    }
  }
}

.fleet-first-line {
  display: flex;
  width: 100%;
}

.fleet-item-title-id-none {
  color: #6f7072;
  background-color: #c9cacc;
  font-weight: 600;
  padding: 1px 8px;
  font-size: 10px;
  border-radius: 2px;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.animation-edition {
  transition: margin-left 0.5s;
  margin-left: 20px;
}