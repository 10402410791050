.detail-modal {
  overflow: hidden;
  .claim-header-inner {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    z-index: 1;
    text-align: left;
    margin: 15px 0 15px 0;
    > div > .btn-icon {
      height: 22px;
      width: 22px;
      margin-right: 14px;
    }
    > div > h5 {
      font-weight: bold;
      line-height: 1;
      margin-bottom: 5px;
      font-size: 17px;
    }
    > div > p {
      color: #8b8b8b;
      font-weight: 600;
      line-height: 1;
      font-size: 12px;
      margin-bottom: 5px;
    }
  }
  .vehicle-detail-modal-body {
  }
  .claim-main-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 15px;
    > .left-part {
      position: relative;
      flex: 1 0 calc(50% - 25px);
      display: flex;
      flex-direction: column;

      .status-claim {
        background-color: #f6b93b;
        margin-right: 19px;
        &:before {
          background-color: #f6b93b;
        }
      }

      > .bg-deco-fleet {
        position: absolute;
        top: -100px;
        right: -23%;
        height: 660px;
        width: auto;
        z-index: 0;
        user-select: none;
      }

      > .tour-upload-item-wrapper {
        position: relative;
        z-index: 1;
        min-height: 200px;
        width: 100%;
        margin-bottom: 25px;
        overflow-y: auto;
      }
    }
    > .mid-part {
      flex: 0 1 50px;
      padding: 7px;
      display: flex;
      align-items: center;
      justify-content: center;
      > .chevron-deco-fleet {
        width: 30px;
        height: auto;
      }
    }
    .right-part {
      position: relative;
      flex: 1 0 calc(50% - 25px);
      display: flex;
      flex-direction: column;

      .status-claim {
        background-color: #079992;
        margin-right: 19px;
        &:before {
          background-color: #079992;
        }
      }

      .distribution-container {
        position: relative;

        .distribution-wrapper {
          position: relative;
          // display: flex;
          // flex-direction: row;
          // justify-content: center;
          .swiper-wrapper {
            padding-bottom: 30px;
          }

          .swiper-container-horizontal > .swiper-pagination-bullets,
          .swiper-pagination-custom,
          .swiper-pagination-fraction {
            bottom: 0px !important;
          }
          .swiper-container {
            /*this gives the dimension of slider container*/
            /*these values will make the container as big as the parent div*/
            /*important parameter to not disrupt the container when slides increases in coverflow and other effect*/
            overflow-y: visible;
            overflow-x: hidden;
            .swiper-pagination {
              position: absolute;
              bottom: -25px; // should be fine as is, but adjust to your liking
              .swiper-pagination-bullet-active {
                background-color: #079992;
              }
            }

            .swiper-slide {
              width: 125px;
              height: calc(100% + 20px);
              /*this one holds the dimension of each slides and can be changed according to the user wish*/
            }
            .swiper-button-next,
            .swiper-button-prev {
              color: white;
              font-weight: 700;
              background-color: #079992;
              padding: 20px;
              border-radius: 15px;
              box-shadow: rgba(149, 157, 165, 0.5) 0px 8px 24px;
              border-bottom: 3px solid #0a8a84;
              &:hover {
                background-color: #079992;
              }
              &:active {
                border-bottom: 1px solid #0a8a84;
              }
              &:after {
                font-size: 15px;
              }
            }
            .swiper-button-prev.swiper-button-disabled,
            .swiper-button-next.swiper-button-disabled {
              pointer-events: initial;
              border-bottom: none;
            }
          }
          .distribution-item-container {
            margin-top: 18px;
            .distribution-item {
              position: relative;
              width: 125px;
              border-radius: 9px;
              border: 3px solid #707070;
              border-style: dashed;
              &::before {
                content: "";
                padding-bottom: 150%;
                display: block;
              }
              .distribution-item-bloc-wrapper {
                position: absolute;
                inset: 0;
                padding: 4px;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;

                > .distribution-item-bloc {
                  margin: 2px 0;
                  position: relative;
                  width: 100%;
                  background: #074699;
                  border: 3px solid #06336e;
                  white-space: nowrap;
                  color: white;
                  font-weight: bold;
                  text-align: center;
                  transition: all 0.3s ease-in-out;
                  font-size: 12px;
                  border-radius: 6px;
                  box-sizing: border-box;

                  span {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                  }
                }
              }
              .distribution-container-number {
                position: absolute;
                top: -20px;
                height: 40px;
                width: 40px;
                left: 50%;
                transform: translateX(-50%);
                background-color: #079992;
                text-align: center;
                line-height: 40px;
                color: white;
                font-weight: bold;
                border-radius: 50%;
                z-index: 1;
              }
            }
          }
        }
      }
    }
  }
}
body .modal-70w {
  max-width: 1100px;
  @media screen and (max-width: 992px) {
    max-width: 1100px;
  }
  @media screen and (max-width: 792px) {
    max-width: 800px;
  }
  @media screen and (max-width: 576px) {
    max-width: 500px;
  }
}

.modal-content {
  border: none !important;
  padding: 0 1.5rem;
  .modal-header {
    border-bottom: none;
  }
  .modal-footer {
    border-top: none;
  }
}

.tour-upload-button {
  position: relative;
  z-index: 1;
  width: 100%;
  margin-bottom: 25px;
  display: flex;
  .button-confirm {
    height: 65px;
    width: 100%;
    background-color: #079992;
    border-radius: 15px;
    border-bottom: 4px solid #0a8a84;
    color: white;
    font-weight: 500;
    font-size: 15px;
    &:hover {
      color: white;
      background-color: #10a39c;
    }
    &:active {
      border-bottom: 1px solid #06726c;
    }
  }

  .left-part {
    flex: 1 0 55%;
  }
  .right-part {
    flex: 1 0 45%;
    padding-left: 10px;
  }
}

.claim-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  > .status-claim {
    position: relative;
    content: "";
    opacity: 0.7;
    left: 4px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #eb2f06;
    margin-right: 19px;
    &:before {
      opacity: 0.3;
      position: absolute;
      content: "";
      top: -50%;
      left: -4px;
      width: 16px;
      height: 16px;
      border-radius: 4px;
      background-color: #eb2f06;
      transform-origin: 50% 0 0;
    }
  }
  > h5 {
    font-weight: bold;
    line-height: 1;
    font-size: 17px;
    margin-bottom: 0;
  }
  > p {
    color: #8b8b8b;
    font-weight: 600;
    line-height: 1;
    font-size: 12px;
    margin-bottom: 0;
  }
}
.claim-container {
  background: #ececec;
  position: relative;
  z-index: 1;

  border-radius: 10px;
  padding: 7px 5px;
  > .claim-container-inner {
    position: relative;
    background: #e3e3e3;
    border-radius: 10px;
    min-height: 500px;
    max-height: 500px;
    height: 100%;
    padding: 27px 10px;
    overflow: hidden;
    .no-data-claim {
      font-size: 0.8rem;
      width: 200px;
      color: #c4c4c4;
      font-weight: 500;
      line-height: 1;
      margin: 0 auto;
      text-align: center;
    }
  }
}
