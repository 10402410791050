.fleet-item-optimization {
  position: relative;
  display: block;
  background: white;
  border-radius: 13px;
  margin: 10px 0;
  box-shadow: 0px 1px 1px 0px rgba(14, 42, 90, 0.15);
  overflow: hidden;
  min-width: 300px;
  transition: box-shadow 0.2s;

  &:hover {
    // background: #fcfcfc;
    box-shadow: 0px 1px 1px 0px rgba(14, 42, 90, 0.23);

    .fr {
      >.fleet-item-title {
        color: #585757 !important;
      }

      >.fleet-item-desc {
        color: #646363;
      }
    }
  }

  .fleet-item-step-optimization-container-tr {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 17px;
      bottom: 17px;
      left: 119px;
      width: 3px;
      border-left: 2px dashed #e9ecef;
      z-index: 0;

      @media screen and (max-width: 1620px) {
        left: 59px;
      }
    }
  }

  .fleet-first-line-tour {
    width: 100%;
    display: flex;
    padding: 10px;
    cursor: pointer;
  }

  >.fleet-first-line-tour>.hw {
    flex: 0 0 105px;

    @media screen and (max-width: 1620px) {
      flex: 0 0 70px;
    }

    >.img-illustration-fleet-2 {
      position: absolute;
      top: 12px;
      left: 5px;
      height: 100px;
      width: auto;

      @media screen and (max-width: 1620px) {
        position: absolute;
        top: 0;
        left: 5px;
        height: 70px;
        width: auto;
      }
    }
  }

  .hwe-opti {
    flex: 0 0 125px;

    .truck-capacity {
      padding: 5px 14px;
      font-size: 12px;
      background-color: #e4ecf1;
      color: #a0a3a5;
      border-radius: 10px;
    }

    .fleet-item-delete {
      height: 40px;
      width: 40px;
      background-color: #079992;
      font-size: 19px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      border-radius: 10px;
      border-bottom: 2px solid #06726c;
      color: white;
      box-shadow: 0px 0px 6px 0px rgba(36, 43, 39, 0.15);
      transition: all 0.3s linear;

      &:hover {
        background-color: #10a39c;
      }

      &:active {
        border-bottom: 1px solid #06726c;
      }

      .fleet-icon {
        pointer-events: none;
      }
    }
  }

  .fr {
    >.fleet-item-title {
      white-space: nowrap;
      text-align: left;
      display: block;
      font-size: 15px;
      line-height: 1;
      font-weight: 700;
      color: #333333;
    }

    >.fleet-item-desc {
      display: block;
      text-align: left;
      font-size: 12px;
      line-height: 1;
      font-weight: 500;
      color: #333333;
    }

    flex-grow: 1;
    /* default 0 */
  }
}

.fleet-first-line {
  display: flex;
  width: 100%;
}

.fleet-other-item {
  flex-direction: row;
  display: flex;
}

.flex-item-input {
  display: block;
  flex-grow: 1;
  text-align: center;
}

.flex-item-input-label {
  font-family: Montserrat-Medium, sans-serif;
  color: #b4b4b4;
}

.flex-item-input-result {
  font-family: Montserrat-Medium, sans-serif;
  color: #000000;
}

.fleet-item-collapsed {
  margin-top: 0px;
}

.img-illustration-fleet {
  position: absolute;
  top: -50px;
  left: -25px;
  height: 150px;
  width: auto;
}

.fleet-item-desc-light {
  position: relative;
  display: block;
  text-align: left;
  font-size: 14px;
  font-family: Montserrat-SemiBold, sans-serif;
  color: #ffffff;
  z-index: 0;
}

.fleet-item-desc2 {
  display: block;
  text-align: left;
  font-size: 14px;
  font-family: Montserrat-Medium, sans-serif;
  color: #bababa;
  margin-left: 5px;
}

.fleet-item-desc2-light {
  margin-top: 5px;
  display: block;
  text-align: left;
  font-size: 14px;
  font-family: Montserrat-Medium, sans-serif;
  color: #6e6e6e;
  margin-left: 5px;
}

.fleet-item-desc3 {
  display: block;
  text-align: left;
  font-size: 14px;
  font-family: Montserrat-Medium, sans-serif;
  color: #717171;
  margin-left: 5px;
}