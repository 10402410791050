.fleet-item-bl {
  font-family: Montserrat-Medium, sans-serif;
}
.card-loader-5 {
  border: none !important;
}
.fleet-item-bl-number {
  font-family: Montserrat-Bold, sans-serif;
  position: relative;
  color: #000000;
}
.fleet-item-bl-list-item {
  margin-left: 15px;
}
body .fleet-item-bl-list-item > .card {
  border: none;
}
body .card-header-no-css {
  padding: 0;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0);
  border-bottom: none;
}
.fleet-item-information-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}
.fleet-item-information-container img {
  height: 30px;
  width: auto;
}
.fleet-item-information-container span {
  color: #b1b1b1;
  margin-left: 10px;
  font-size: 15px;
}
/***
    Step background color
 ***/

.fleet-item-steps-container-optimization {
  display: block;

  .fleet-item-steps-container-inner {
    padding: 0 15px 15px 15px;
  }
  .fleet-item-step-no-item {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    min-width: 608px;
    position: relative;
    margin-left: 200px;
    overflow: hidden;
    > p {
      font-weight: 500;
      color: #dadada;
    }
  }
  .fleet-item-step-optimization-container {
    position: relative;
  }
  .fleet-item-bl-optimization {
    min-width: 300px;
    padding: 10px;
    position: relative;
    margin-top: 10px;
    border-radius: 10px;
    margin-left: 140px;
    background-color: #f6f8fa;
    display: flex;
    overflow: hidden;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.05);
    cursor: normal;
    transition: box-shadow 0.2s;
    @media screen and (max-width: 1620px) {
      margin-left: 80px;
    }

    &:hover {
      box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.08);
    }
    > .fleet-first-line > .hw {
      flex: 0 0 90px;
      .img-illustration-fleet-step {
        top: -3px;
        left: 3px;
        height: 55px;
        width: auto;
      }
    }
    > .fleet-first-line > .fr {
      display: flex;
      align-items: center;
      > .fleet-item-title {
        white-space: nowrap;
        text-align: left;
        display: block;
        font-size: 15px;
        line-height: 1;
        font-weight: 700;
        color: #333333;
      }
      > .fleet-item-desc {
        display: block;
        text-align: left;
        font-size: 12px;
        line-height: 1;
        font-weight: 400;
        color: #333333;
      }
      flex-grow: 1; /* default 0 */
    }
    > .fleet-first-line > .hwe {
      flex: 0 0 60px;
      display: flex;
      align-items: center;
      > .hwe-child-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        .truck-capacity {
          padding: 5px 14px;
          font-size: 12px;
          background-color: #e4ecf1;
          color: #a0a3a5;
          border-radius: 10px;
        }
      }

      &.active {
        background-color: #079992;
      }
    }
  }
}
.fleet-item-step.active .fleet-item-title,
.fleet-item-step.active .fleet-item-desc,
.fleet-item-step.active .fleet-item-arrival {
  color: white;
}
.fleet-item-step.active > .fleet-first-line > .hwe > .hwe-child-wrapper > span,
.fleet-item-step.active .fleet-item-status {
  color: #dbdbdb;
}
.fleet-item-step.active .fleet-item-status::after,
.fleet-item-step.active .fleet-item-status::before {
  background-color: #dbdbdb !important;
}
