body .text-muted-vehicle {
      font-size: 80%;
      font-weight: 400;
      display: block;
      margin-top: .25rem;
}

.form-control-vehicle {
  margin-bottom:1rem;
}

.fleet-item>.fleet-first-line-vehicle>.fr>.fleet-item-title {
  white-space: nowrap;
  text-align: left;
  display: block;
  font-size: 20px;
  font-weight: 700;
  color: #333333;
}

.fleet-item>.fleet-first-line-vehicle>.fr>.fleet-item-desc {
  display: block;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  color: #333333;
}

.fleet-item>.fleet-first-line-vehicle>.fr>.fleet-item-desc-light {
  position: relative;
  display: block;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  z-index: 0;
}