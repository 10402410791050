.page-wrapper {
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 2100px;
}

.fleet-management-tour-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 0 0 auto;
  padding: 5px 20px 5px 10px;
  color: black;

  >.tour-header-left {
    position: relative;
    display: flex;
    align-items: center;

    >.tour-header-title {
      font-weight: bold;
      line-height: 1;
      font-size: 18px;
    }

    >.tour-header-number {
      color: #fff;
      background-color: #079992 !important;
      padding: 7px 5px;
      margin-left: 10px;
    }
  }

  >.tour-header-right {
    .btn-add-truck {
      border-radius: 10px;
      background-color: #f7f7f9;
      font-weight: 600;
      font-size: 13px;

      >.icon-rounded-plus {
        color: #079992;
        margin-right: 10px;
      }

      @media screen and (max-width: 1522px) {
        font-size: 11px;
      }

      @media screen and (max-width: 1436px) {
        font-size: 10px;
      }

      @media screen and (max-width: 1436px) {
        font-size: 12px;
        margin-left: 0px !important;
        margin-top: 10px;
      }
    }
    @media screen and (max-width: 1436px) {
      display: grid;
    }
  }
}

.fleet-management-content-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex: 1 1 auto;
  position: relative;
  min-height: 0;
  padding-top: 57px;

  >.fleet-management-content-left-part {
    flex: 0 0 50%;
    display: flex;
    flex-direction: column;
    min-height: 0;
    height: 100%;
    max-height: 100%;
    min-width: 385px;
    max-width: 840px;

    >.tours-container {
      display: block;
      flex-grow: 1;
      padding: 20px 10px 20px 10px;
      overflow-y: hidden;
      position: relative;
      min-height: 0;
      height: 100%;
      width: 100%;
      margin-bottom: -15px;

      >.wrapper {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
  }

  >.fleet-management-content-right-part {
    flex: 0 0 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 0 0 40px;

    >.fleet-management-map-part {
      flex: 0 0 100%;
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;

      >.map-div-container {
        padding: 15px 32px;
        border-radius: 27px;
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 1px;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;

        >.map-div-header {
          margin-bottom: 20px;

          >.map-header-div-one {
            >.map-header-title {
              font-weight: bold;
              line-height: 1;
              font-size: 18px;
              display: block;
            }

            >.map-header-subtitle {
              font-size: 12px;
              font-weight: 600;
              line-height: 1;
              color: #929292;
              margin-bottom: 0;
              margin-top: 5px;
            }
          }

          >.map-header-number {
            color: #fff;
            background-color: #079992 !important;
            padding: 7px 5px;
          }
        }

        >.map-dashboard-container {
          flex: 1 1 auto;
          min-width: 0;
          max-height: 300px;
          min-height: 250px;
          background-color: #f6f6f4;
          border-radius: 27px;
          height: 100%;
          width: 100%;
          position: relative;

          .map-wrapper {
            border-radius: 27px;
          }
        }

        .platform-container {
          flex: 1 1 auto;
          min-width: 0;
          position: relative;
          margin: 10px 0;

          display: flex;
          flex-direction: column;
          overflow-y: hidden;
        }
      }
    }
  }
}