.detail-modal {
  overflow: hidden;

  .upload-header-inner {
    position: relative;
    z-index: 1;
    text-align: left;
    margin: 15px 0 0 0;

    >h5 {
      font-weight: bold;
      line-height: 1;
      margin-bottom: 5px;
      font-size: 17px;
    }

    >p {
      color: #8b8b8b;
      font-weight: 600;
      line-height: 1;
      font-size: 12px;
      margin-bottom: 5px;
    }
  }

  .vehicle-detail-modal-body {}

  .upload-main-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .left-part {
      position: relative;
      flex: 1 0 55%;
      display: flex;
      flex-direction: column;
      max-height: calc(100vh - 300px);

      >.bg-deco-fleet {
        position: absolute;
        top: -100px;
        right: -23%;
        height: 660px;
        width: auto;
        z-index: 0;
        user-select: none;
      }

      >.upload-select-simulation-type-wrapper {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 25px;

        >.upload-select-simulation-type {
          background-color: #079992;
          border-radius: 20px;
          display: block;
          padding: 3px;

          >.btn-simulation {
            font-weight: 400;
            user-select: none;
            text-align: center;
            height: 65px;
            padding: 0 50px;
            width: auto;
            background: white;
            border: 1px solid transparent;
            border-radius: 20px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            color: #079992;

            >.btn-simulation-title {
              display: block;
              font-size: 14px;
              font-weight: 700;
              line-height: 1;
              margin-bottom: 4px;
            }

            >.btn-simulation-desc {
              display: block;
              padding: 0.5em 0.5em;
              background-color: #079992;
              font-size: 12px;
              font-weight: 600;
              color: white;
              border-radius: 6px;
              line-height: 1;
            }
          }
        }
      }

      >.tour-upload-item-wrapper {
        position: relative;
        z-index: 1;
        min-height: 200px;
        width: 100%;
        margin-bottom: 25px;
        overflow-y: auto;
      }
    }

    .right-part {
      flex: 1 1 45%;
      padding-left: 10px;
      max-width: 45%;

      .distribution-header {
        text-align: center;
        margin-bottom: 40px;

        >h5 {
          font-weight: bold;
          line-height: 1;
          margin-bottom: 5px;
          font-size: 17px;
        }

        >p {
          color: #8b8b8b;
          font-weight: 600;
          line-height: 1;
          font-size: 12px;
          margin-bottom: 5px;
        }
      }

      .distribution-container {
        position: relative;

        .distribution-wrapper {
          position: relative;

          // display: flex;
          // flex-direction: row;
          // justify-content: center;
          .swiper-wrapper {
            padding-bottom: 30px;
          }

          .swiper-container-horizontal>.swiper-pagination-bullets,
          .swiper-pagination-custom,
          .swiper-pagination-fraction {
            bottom: 0px !important;
          }

          .swiper-container {
            /*this gives the dimension of slider container*/
            /*these values will make the container as big as the parent div*/
            /*important parameter to not disrupt the container when slides increases in coverflow and other effect*/
            overflow-y: visible;
            overflow-x: hidden;

            .swiper-pagination {
              position: absolute;
              bottom: -25px; // should be fine as is, but adjust to your liking

              .swiper-pagination-bullet-active {
                background-color: #079992;
              }
            }

            .swiper-slide {
              width: 125px;
              height: calc(100% + 20px);
              /*this one holds the dimension of each slides and can be changed according to the user wish*/
            }

            .swiper-button-next,
            .swiper-button-prev {
              color: white;
              font-weight: 700;
              background-color: #079992;
              padding: 20px;
              border-radius: 15px;
              box-shadow: rgba(149, 157, 165, 0.5) 0px 8px 24px;
              border-bottom: 3px solid #0a8a84;

              &:hover {
                background-color: #079992;
              }

              &:active {
                border-bottom: 1px solid #0a8a84;
              }

              &:after {
                font-size: 15px;
              }
            }

            .swiper-button-prev.swiper-button-disabled,
            .swiper-button-next.swiper-button-disabled {
              pointer-events: initial;
              border-bottom: none;
            }
          }

          .distribution-item-container {
            margin-top: 18px;

            .max-container {
              font-weight: 500;
              font-size: 10px;
              color: white;
              background-color: #8b8b8b;
            }

            .distribution-item {
              position: relative;
              width: 125px;
              border-radius: 9px;
              border: 3px solid #707070;
              border-style: dashed;

              &::before {
                content: "";
                padding-bottom: 150%;
                display: block;
              }

              .distribution-item-bloc-wrapper {
                position: absolute;
                inset: 0;
                padding: 4px;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;

                >.distribution-item-bloc {
                  margin: 2px 0;
                  position: relative;
                  width: 100%;
                  background: #074699;
                  border: 3px solid #06336e;
                  white-space: nowrap;
                  color: white;
                  font-weight: bold;
                  text-align: center;
                  transition: all 0.3s ease-in-out;
                  font-size: 12px;
                  border-radius: 6px;
                  box-sizing: border-box;

                  span {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                  }
                }
              }

              .distribution-container-number {
                position: absolute;
                top: -20px;
                height: 40px;
                width: 40px;
                left: 50%;
                transform: translateX(-50%);
                background-color: #079992;
                text-align: center;
                line-height: 40px;
                color: white;
                font-weight: bold;
                border-radius: 50%;
                z-index: 1;
              }
            }
          }
        }
      }
    }
  }
}

body .modal-90w {
  width: 90%;
  max-width: none !important;
}

.modal-content {
  border: none !important;
  padding: 0 1.5rem;

  .modal-header {
    border-bottom: none;
  }

  .modal-footer {
    border-top: none;
  }
}

.tour-upload-button {
  position: relative;
  z-index: 1;
  width: 100%;
  margin-bottom: 25px;
  display: flex;

  .button-confirm {
    height: 65px;
    width: 100%;
    background-color: #079992;
    border-radius: 15px;
    border-bottom: 4px solid #0a8a84;
    color: white;
    font-weight: 500;
    font-size: 15px;

    &:hover {
      color: white;
      background-color: #10a39c;
    }

    &:active {
      border-bottom: 1px solid #06726c;
    }
  }

  .left-part {
    flex: 1 0 55%;
  }

  .right-part {
    flex: 1 0 45%;
    padding-left: 10px;
  }
}