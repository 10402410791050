.issue-container {
  position: relative;
  display: block;
  border-radius: 7px;
  margin: 10px;
  overflow: hidden;
  background-color: #07807a;
  color: #525252;
  transition: 0.1s;
  box-shadow: 0px 1px 1px 0px rgba(14, 42, 90, 0.15);

  &.disabled {
    opacity: 0.5;
    filter: grayscale(70%);
  }
  > .decoration {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    > .left-ticket {
      position: absolute;
      top: 50%;
      left: -4px;
      width: 10px;
      height: 10px;
      background-color: #e3e3e3;
      border-radius: 5px;
    }
    > .right-ticket {
      position: absolute;
      top: 50%;
      right: -4px;
      width: 10px;
      height: 10px;
      background-color: #e3e3e3;
      border-radius: 5px;
    }
    > .title-overlay {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 38px;
      background-color: #079992;
    }
    .bgc-overlay {
      position: absolute;
      top: 30%;
      right: 0;
      left: 0;
      height: 60px;
      background: rgb(2, 0, 36);
      background: linear-gradient(
        0deg,
        rgba(2, 0, 36, 0) 0%,
        rgba(0, 0, 0, 0.036852240896358524) 49%,
        rgba(0, 212, 255, 0) 100%
      );
      z-index: 0;
    }
  }
  .issue-container-header {
    padding: 10px 15px;
    background-color: #079992;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > .left-part {
      a {
        text-decoration: none;
        > .issue-container-header-bl {
          color: white;
          font-weight: bold;
          line-height: 1;
          > .issue-container-header-bl-icon {
            height: 16px;
            width: 16px;
            margin-right: 8px;
          }
        }
      }
    }
    > .right-part {
      text-align: right;
      > .issue-container-header-hour {
        color: white;
        font-weight: 500;
        font-size: 12px;
      }
    }
  }
  > .issue-container-body {
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    > p {
      color: white;
      font-weight: 500;
      font-size: 0.9rem;
      width: 100%;
    }
    > .issue-container-body-button {
      font-weight: bold;
      text-transform: uppercase;
      background-color: #ffffff;
      font-size: 14px;
      border-radius: 9px;
      font-weight: 600;
      border: none;
      color: black;
      /* float: right; */
      transition: all 0.1s;
      position: relative;
      z-index: 1;
      margin-top: 20px;
    }
    > .issue-container-body-button-placeholder {
      height: 33px;
      margin-top: 20px;
    }
  }
}
