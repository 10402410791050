.fleet-platform-list {
  position: relative;
  display: block;
  background: #f6f8fa;
  border-radius: 13px;
  margin-bottom: 10px;
  box-shadow: 0px 1px 1px 0px rgba(14, 42, 90, 0.15);
  overflow: hidden;
  min-width: 385px;
  transition: all 0.2s;

  &.disabled {
    opacity: 0.5;
    transform: scale(0.98);
  }

  &:hover {
    box-shadow: 0px 1px 1px 0px rgba(14, 42, 90, 0.23);
  }

  .fleet-first-line-tour-management {
    width: 100%;
    display: flex;
    padding: 10px 13px;
  }

  >.fleet-first-line-tour-management>.hw {
    flex: 0 0 20px;
    margin-top: 3px;

    >.rounded-background {
      background-color: #079992;
      border: 3px solid #fff;
      border-radius: 50%;
      width: 27px;
      height: 27px;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.137);

      >.span-color {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 20px;
        color: white;
        margin: 0;
        font-size: 12px;
        font-weight: bold;
      }
    }

    @media screen and (max-width: 1620px) {
      flex: 0 0 10px;
    }

    @media screen and (max-width: 1200px) {
      flex: 0 0 10px;
    }

    >.img-illustration-box-list {
      position: absolute;
      height: 80px;
      width: auto;
      object-fit: none;
      left: 0px;

      @media screen and (max-width: 1620px) {
        position: absolute;
        width: auto;
        left: 0px;
      }

      @media screen and (max-width: 1200px) {
        height: 80px;
        width: auto;
        object-fit: none;
        left: 0px;
      }
    }
  }

  .fr {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 20px;
    justify-content: center;

    >.fleet-item-title {
      white-space: nowrap;
      text-align: left;
      display: block;
      font-size: 16px;
      line-height: 1;
      font-weight: 700;
      color: #333333;

      @media screen and (max-width: 1200px) {
        font-size: 13px;
      }
    }

    >.fleet-item-address {
      display: block;
      text-align: left;
      font-size: 11px;
      line-height: 1;
      font-weight: 600;
      color: #333333;

      @media screen and (max-width: 1200px) {
        font-size: 10px;
      }
    }

    flex-grow: 1;
    /* default 0 */
  }
}

.platform-list-container {
  flex: 1 1 auto;
  height: 100%;
  width: 100%;
  min-height: 0;
  overflow-y: hidden;
  position: relative;
}

.fleet-more-platform {
  flex: 0 0 auto;
  margin: 9px 0;

  >.fleet-more-platform-header {
    >.fleet-button-add {
      display: flex;
      align-items: center;

      >.btn-icon-more-platform {
        color: #079992;
        margin-right: 10px;
      }

      >.fleet-span-text {
        color: #079992;
        font-weight: 600;
        font-size: 13px;
      }

      &:hover {
        background-color: #07999215;
      }
    }
  }
}

.fleet-item-tour-compartiment-horizontal-divider {
  width: 98%;
  min-width: 98%;
  margin: 10px 0;
  box-sizing: border-box;
  padding: 0;
  position: relative;

  &:after {
    content: "";
    width: 100%;
    height: 7px;
    background: rgb(236, 237, 239);
    background: linear-gradient(0deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(0, 0, 0, 0) 100%);
    position: absolute;
    top: -17px;
    left: 0;
    right: 0;
  }
}