body .pagination{
  gap: 7px;  
  justify-content: center;
} 

.page-item > .page-link {
  font-size:13px;
  font-family: 'Work Sans', sans-serif;
  font-weight: 500;
  padding: 8px 7px;
  background-color: transparent;
  border: none;
  color: #079992;
  border-radius : 4px;
  user-select: none;
} 

.page-item > .page-link:hover {
   color: #079992;
} 
.page-item > .page-link:focus {
   color: #079992;
   box-shadow: none;
} 

body .page-item.active > .page-link {
  background-color: #079992;
  color:white;

}

.pagination-count{
  font-size:12px;
  font-family: 'Work Sans', sans-serif;
  font-weight: 400;
  text-align: center;
}

.pagination-count > p{
  margin-bottom: 8px;
  color: rgba(0, 0, 0, 0.55);
}