// HEADER // HEADER // HEADER // HEADER
.video-background {
  width: 100%;
  height: 250px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.remove-padding>.modal-content {
  padding: 0px 0px;
}


.modal-help-header {
  padding: 0px 0px !important;

  >.btn-close {
    color: white !important;
    background-color: #079992 !important;
    position: absolute;
    border-radius: 30px;
    top: 30px;
    right: 30px;
    background: transparent url(../../../../../assets/images/optimisation/close-button.png) center/1em auto no-repeat;
    opacity: 1;
  }
}

// BODY // BODY // BODY // BODY

.modal-help-body {

  >.badge-onboarding {
    background-color: #06726c;
    width: 60px;
    border-radius: 20px;
    height: 26px;

    >.badge-title {
      color: white;
      font-size: 11px;
      font-weight: 500;
      margin-left: 12px;
    }
  }

  >.title {
    font-size: 18px;
    color: #079992;
  }

  >.text {
    color: #333333;
    font-size: 14px;
    font-weight: 500;
  }
}

// FOOTER // FOOTER // FOOTER // FOOTER

.modal-help-footer {
  >.modal-btn-footer {
    height: 40px !important;
    width: 130px !important;
    background-color: #079992 !important;
    border-radius: 11px !important;
    border-bottom: 4px solid #0a8a84 !important;
    color: white !important;
    font-weight: 400 !important;
    font-size: 15px !important;
    text-decoration: underline !important;
    text-decoration-color: #079992 !important;
  }

  >.modal-btn-footer:active {
    border-bottom: 1px solid #06726c !important;
  }

  >.modal-btn-footer:hover {
    background-color: #10a39c !important;
  }
}